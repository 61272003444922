import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const GottUndDerMenschZitate = () => (
    <InnerPage title="Zitate – Gott und der Mensch" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Gott und der Mensch"
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Gott und der Mensch',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Brief an den Sohn des Wolfes, 1:172">
                    Gott in Seinem Wesen und Seinem Urselbst ist allezeit
                    unsichtbar, unerreichbar und unerforschlich.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese, 94:1">
                    Er ist und war von Ewigkeit her einzig und allein, ohne
                    Gefährten oder Seinesgleichen, ewig in der Vergangenheit,
                    ewig in der Zukunft, gesondert von allen Dingen,
                    immerwährend, unveränderlich und selbstbestehend.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Die Verborgenen Worte, arab. Nr. 3">
                    O Sohn des Menschen!
                    <br />
                    Verhüllt in Meinem unvordenklichen Sein und in der
                    Urewigkeit Meines Wesens, wußte Ich um Meine Liebe zu dir.
                    Darum erschuf Ich dich, prägte dir Mein Ebenbild ein und
                    offenbarte dir Meine Schönheit.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese, 27:2">
                    Nachdem Er die Welt und alles, was darin lebt und webt,
                    erschaffen hatte, wünschte Er durch das unmittelbare Wirken
                    Seines unumschränkten, höchsten Willens, dem Menschen die
                    einzigartige Auszeichnung und Fähigkeit zu verleihen, Ihn zu
                    erkennen und zu lieben – eine Fähigkeit, die man als den der
                    gesamten Schöpfung zugrunde liegen­den schöpferischen
                    Antrieb und Hauptzweck ansehen muß… Auf die innerste
                    Wirklichkeit jedes erschaffenen Dings hat Er das Licht eines
                    Seiner Namen ergossen; jedes hat Er zum Empfänger der
                    Herrlichkeit einer Seiner Eigenschaften gemacht. Die
                    Wirklichkeit des Menschen jedoch hat Er zum Brennpunkt für
                    das Strahlen aller Seiner Namen und Attribute und zum
                    Spiegel Seines eigenen Selbstes erkoren. Von allem
                    Erschaffenen ist allein der Mensch zu einer so großen Gunst,
                    einer so dauerhaften Gabe auserwählt.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese, 21">
                    Das Tor zur Erkenntnis des Altehrwürdigen Seins ist immer
                    vor den Menschen verschlossen gewesen und wird es für immer
                    bleiben. Kein menschliches Begreifen wird jemals zu Seinem
                    heiligen Hofe Zutritt erlangen. Als Zeichen Seiner
                    Barmherzigkeit und als Beweis Seiner Gnade hat Er jedoch den
                    Menschen die Sonnen Seiner göttlichen Führung, die
                    Sinnbilder Seiner göttlichen Einheit offenbart und verfügt,
                    daß die Erkenntnis dieser geheiligten Wesen mit der
                    Erkenntnis Seines eigenen Selbstes gleichzusetzen sei. Wer
                    sie erkennt, hat Gott erkannt. Wer auf ihren Ruf hört, hat
                    auf die Stimme Gottes gehört, und wer die Wahrheit ihrer
                    Offenbarung bezeugt, hat die Wahrheit Gottes selbst bezeugt.
                    Wer sich von ihnen abwendet, hat sich von Gott abgewandt,
                    und wer nicht an sie glaubt, hat nicht an Gott geglaubt.
                    Jeder von ihnen ist der Pfad Gottes, der diese Welt mit den
                    Reichen der Höhe verbindet, und das Banner Seiner Wahrheit
                    für alle in den Reichen der Erde und des Himmels. Sie sind
                    die Manifestationen Gottes unter den Menschen, die Beweise
                    Seiner Wahrheit und die Zeichen Seiner Herrlichkeit.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Kitab-i-Iqan, 2:60">
                    Auch wirst du verstehen, daß Gottes Treuhänder bei den
                    Völkern der Erde als Vertreter einer neuen Sache und Träger
                    einer neuen Botschaft erscheinen. Da diese Vögel des
                    himmlischen Thrones alle aus dem Himmel des Willens Gottes
                    herabgesandt sind, da sie alle sich erheben, Seinen
                    unwiderstehlichen Glauben zu verkünden, sind sie wie eine
                    Seele und ein Wesen anzusehen. Denn sie alle trinken aus
                    demselben Kelch der Liebe Gottes, und alle haben sie teil an
                    der Frucht desselben Baumes der Einheit.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese, 34:7">
                    Nun zu deiner Frage über das Wesen der Religion. Wisse, daß
                    die wahrhaft Weisen die Welt mit dem menschlichen Tempel
                    vergleichen. Wie der Leib des Menschen eines Gewandes
                    bedarf, sich zu kleiden, so muß der Menschheit Leib mit dem
                    Mantel der Gerechtigkeit und Weisheit ge­schmückt sein. Ihr
                    Prachtgewand ist die Offenbarung, die Gott ihr gewährt hat.
                    Wann immer dieses Gewand seinen Zweck erfüllt hat, wird der
                    Allmächtige es gewiß erneuern. Denn eine jede Zeit erfordert
                    ein neues Maß des göttlichen Lichtes. Jede göttliche
                    Offenbarung wurde so herabgesandt, wie sie den Verhältnissen
                    des Zeitalters entsprach, in dem sie erschienen ist.
                </NormalQuote>

                <NormalQuote reference="Shoghi Effendi, Der verheißene Tag ist gekommen, Vorwort, S. 7">
                    Die Anhänger dieses Glaubens stehen fest zu dem
                    grundlegenden Prinzip, wie es von Bahá’u’lláh verkündet
                    worden ist, daß religiöse Wahrheit nicht absolut, sondern
                    relativ ist, daß Gottesoffenbarung ein fortdauerndes und
                    fortschreitendes Geschehnis ist, daß alle großen Religionen
                    der Welt göttlich in ihrem Ursprung sind, daß ihre
                    Grundsätze miteinander in völligem Einklang stehen, daß ihre
                    Ziele und Absichten ein und dieselben sind, daß ihre Lehren
                    nur Widerspiegelungen der einen Wahrheit sind, daß ihr
                    Wirken sich ergänzt, daß sie sich nur in unwesentlichen
                    Teilen ihrer Lehren unterscheiden und daß ihre Sendungen
                    aufeinanderfolgende geistige Entwichlungsstufen der
                    Menschheit darstellen.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese, 162:1">
                    Der Allbarmherzige hat dem Menschen die Fähigkeit des Sehens
                    verliehen und ihn mit der Kraft des Hörens begabt. Manche
                    haben ihn als die »kleinere Welt« bezeichnet, während er in
                    Wirklichkeit als die »größere Welt« angesehen werden sollte.
                    Die Möglichkeiten, die der Stufe des Menschen in­newohnen,
                    das volle Maß seiner Bestimmung auf Erden, der angeborene
                    Vorzug seiner Wirklichkeit – all dies muß an diesem
                    verheißenen Tage Gottes offenbar werden.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das Geheimnis göttlicher Kultur, S.14">
                    Gott hat uns Augen gegeben, damit wir uns in der Welt
                    umschauen und alles festhalten, was unsere Kultur und unsere
                    Lebenskunst voranbringt. Er hat uns Ohren gegeben, damit wir
                    zu unserem Nutzen auf die Weisheit der Gelehrten und
                    Philosophen hören und uns aufmachen, ihre Lehren zu fördern
                    und in die Tat umzusetzen. Sinne und Fähigkeiten sind uns
                    verliehen worden, damit wir sie dem Dienst am allgemeinen
                    Wohl weihen, so daß wir, die wir uns durch
                    Wahrnehmungsvermögen und Verstand hoch über alle anderen
                    Lebensformen erheben, uns allezeit und in jeder Hinsicht,
                    bei wichtigen und bei nebensächlichen Anlässen, in normalen
                    oder außergewöhnlichen Verhältnissen, darum mühen, die ganze
                    Menschheit sicher in der unbezwingbaren Feste des Wissens zu
                    versammeln. Unablässig sollen wir neue Grundlagen des
                    menschlichen Glückes legen, fortgesetzt sollen wir neue
                    Werkzeuge schaffen und alte verbessern, um dieses Ziel zu
                    erreichen. Wie erhaben, wie hochgeehrt ist ein Mensch, wenn
                    er sich aufmacht, seinen Pflichten nachzukommen…Wir müssen
                    den hochgesinnten, festen Entschluß fassen, uns zu erheben
                    und alle jene Werkzeuge in den Griff zu bekommen, die
                    Frieden, Wohlstand und Glück, Erkenntnis, Kultur und
                    Gewerbefleiß, Würde, Wert und Stufe der gesamten Menschheit
                    vorantreiben. Durch die belebenden Wasser reiner Absicht und
                    selbstlosen Bemühens wird der Boden menschlicher Anlagen vom
                    Blütenmeer seiner eigenen bislang verborgenen
                    Vortrefflichkeiten übersät, er wird rühmenswerte
                    Eigenschaften hervorbringen, blühen und Frucht tragen, bis
                    er sich mit dem Rosengarten der Erkenntnis messen kann, den
                    unsere Vorväter besessen haben.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default GottUndDerMenschZitate;
